import _ from 'lodash';

import { DELIVERY_MODE } from '@/config/bid';
import { TAB_CATEGORY_ENUM } from '@/config/searchkit/bidList';
import {
  getFormattedDateFromUnixTimestamp,
  getUnixTimestampForOffsetDate
} from '@/lib/dateAndTime';
import { PAGE_NAME } from '@/services/partnerPortal.service';

export const getFacetFilterValue = ({ url, key }) => {
  const searchParams = new URLSearchParams(url);
  const facetFiltersValue = searchParams.get('facetFilters');
  if (!facetFiltersValue) {
    return null;
  }

  const keyValuePairs = facetFiltersValue
    .split(',')
    .map((pair) => pair.split(':'));
  const value = _.chain(keyValuePairs)
    .find(([currentKey]) => currentKey === key)
    .last()
    .value();
  return value;
};

export const getBucketsSortedByTabOrder = ({
  attribute,
  buckets,
  e,
  tabCategoryKeysSorted
}) =>
  tabCategoryKeysSorted
    .map(({ value: expectedBucketValue, label }) => {
      const bucket = buckets.find(
        ({ value: bucketValue }) => expectedBucketValue === bucketValue
      );
      const defaultBucket = {
        count: 0,
        data: null,
        exhaustive: true,
        isRefined:
          getFacetFilterValue({
            url: e.results.params,
            key: attribute
          }) === expectedBucketValue,
        label,
        value: expectedBucketValue
      };
      return bucket ? { ...bucket, label } : defaultBucket;
    })
    .filter(Boolean);

const transformDeliveryModeItems = ({ items, e }) =>
  Object.values(DELIVERY_MODE)
    .map(({ value: deliveryModeTypeToInspect, label }) => {
      const itemsOfDeliveryModeToInspect = items.find(
        ({ value: deliveryModeType }) =>
          deliveryModeTypeToInspect === deliveryModeType
      );

      const isRefined =
        getFacetFilterValue({
          url: e.results.params,
          key: 'deliveryMode'
        }) === deliveryModeTypeToInspect;

      const RadioInputLabel = () => (
        <label className='searchkit-filter-menu-item searchkit-delivery-menu-item relative cursor-pointer'>
          <input
            className='checked:content-searchkit-filter-menu-item-checked searchkit-radio searchkit-filter-menu-item-checked'
            defaultChecked={isRefined}
            name='deliveryMode'
            type='radio'
            value={deliveryModeTypeToInspect}
          />
          <span className='before:bg-searchkit-filter-menu-item inline-block w-5 h-5 empty-radio-span'></span>
          <span className='text-sm text-dim-gray font-medium pl-4 self-center'>
            {label}
          </span>
        </label>
      );

      const defaultBucket = {
        count: 0,
        data: null,
        exhaustive: true,
        isRefined,
        label: <RadioInputLabel />,
        value: deliveryModeTypeToInspect
      };

      return itemsOfDeliveryModeToInspect
        ? { ...itemsOfDeliveryModeToInspect, label: <RadioInputLabel /> }
        : defaultBucket;
    })
    .filter(Boolean);

export const transformDeliveryMode = () => (props, e) =>
  transformDeliveryModeItems({ items: props, e });

const commonRefinementKeyValueLabelMap = {
  deliveryMode: {
    keyLabel: 'Delivery Mode',
    getValueLabel: (refinement) => DELIVERY_MODE[refinement.value].label
  },
  eventDateInSeconds: {
    keyLabel: 'Event Date',
    getValueLabel: (refinement) =>
      `${refinement.operator} ${getFormattedDateFromUnixTimestamp({
        unixTimestamp: refinement.value,
        dateFormat: 'Do MMM YYYY'
      })}`
  }
};

const orderRefinementKeyValueLabelMap = {
  ...commonRefinementKeyValueLabelMap,
  eventAddressCity: {
    keyLabel: 'Location'
  },
  partnerOrderTotal: {
    keyLabel: 'Order Value'
  }
};

const deliveryPickupRefinementKeyValueLabelMap = {
  ...commonRefinementKeyValueLabelMap,
  eventAddressCity: {
    keyLabel: 'Location'
  }
};

const bidRefinementKeyValueLabelMap = {
  ...commonRefinementKeyValueLabelMap,
  eventCity: {
    keyLabel: 'Location'
  }
};

const transformCurrentRefinementItemsLabel = ({
  items,
  refinementKeyValueLabelMap
}) =>
  items.map((item) => {
    const {
      keyLabel = _.startCase(item.label),
      getValueLabel = (refinement) => refinement.label
    } = refinementKeyValueLabelMap[item.attribute] || {};

    const refinements = item.refinements.map((refinement) => ({
      ...refinement,
      label: getValueLabel(refinement)
    }));

    return { ...item, label: keyLabel, refinements };
  });

export const transformCurrentRefinementItemsLabelForOrderListing = (items) =>
  transformCurrentRefinementItemsLabel({
    items,
    refinementKeyValueLabelMap: orderRefinementKeyValueLabelMap
  });

export const transformCurrentRefinementItemsLabelForBidListing = (items) =>
  transformCurrentRefinementItemsLabel({
    items,
    refinementKeyValueLabelMap: bidRefinementKeyValueLabelMap
  });

export const transformCurrentRefinementItemsLabelForDeliveryPickupListing = (
  items
) =>
  transformCurrentRefinementItemsLabel({
    items,
    refinementKeyValueLabelMap: deliveryPickupRefinementKeyValueLabelMap
  });

export const getPlaceholderTextForSearchBox = ({
  searchableAttribute,
  searchAttributeLabelList
}) => {
  const filter = searchableAttribute
    ? ({ esDocKey }) => esDocKey === searchableAttribute
    : ({ key }) => key !== 'ALL';

  const suffix = searchAttributeLabelList
    .filter(filter)
    .map(({ label }) => label)
    .join(', ');

  return `by ${suffix}`;
};

export const SSF_ENUM = {
  DELIVERY_MODE: 'deliveryMode',
  DELIVERY_AND_PICKUP_LIST: 'deliveryAndPickupList',
  EVENT_CITY: 'eventAddressCity',
  EVENT_DATE_RANGE_PICKER: 'eventDateInSeconds',
  EVENT_TYPE: 'eventType',
  PARTNER_ORDER_TOTAL_SLIDER: 'partnerOrderTotal',
  PLANNER_NAME: 'plannerName'
};

export const shouldShowFilter = ({ key, pageName }) => {
  const FILTER_VISIBILITY_RULES = {
    [SSF_ENUM.EVENT_DATE_RANGE_PICKER]: [PAGE_NAME.ORDER_LIST.label],
    [SSF_ENUM.DELIVERY_MODE]: [PAGE_NAME.ORDER_LIST.label],
    [SSF_ENUM.PLANNER_NAME]: [
      PAGE_NAME.ORDER_LIST.label,
      PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label
    ],
    [SSF_ENUM.EVENT_CITY]: [
      PAGE_NAME.ORDER_LIST.label,
      PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label
    ],
    [SSF_ENUM.EVENT_TYPE]: [PAGE_NAME.ORDER_LIST.label],
    [SSF_ENUM.PARTNER_ORDER_TOTAL_SLIDER]: [PAGE_NAME.ORDER_LIST.label],
    [SSF_ENUM.DELIVERY_AND_PICKUP_LIST]: [
      PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label
    ]
  };

  return FILTER_VISIBILITY_RULES[key]?.includes(pageName) ?? false;
};

export const shouldShowSortOptions = ({ pageName }) =>
  pageName === PAGE_NAME.ORDER_LIST.label;

export const shouldShowDeliveryPickupRadioGroup = ({ pageName }) =>
  pageName === PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label;

export const DATE_RANGE_ITEMS_FOR_EVENT_DATE = {
  ALL: { label: 'All', key: 'all' },
  TODAY: {
    label: 'Today',
    key: 'today',
    start: getUnixTimestampForOffsetDate({ dateOffset: 0 }),
    end: getUnixTimestampForOffsetDate({ dateOffset: 0, endOfDay: true })
  },
  TOMORROW: {
    label: 'Tomorrow',
    key: 'tomorrow',
    start: getUnixTimestampForOffsetDate({ dateOffset: 1 }),
    end: getUnixTimestampForOffsetDate({ dateOffset: 1, endOfDay: true })
  },
  YESTERDAY: {
    label: 'Yesterday',
    key: 'yesterday',
    start: getUnixTimestampForOffsetDate({ dateOffset: -1 }),
    end: getUnixTimestampForOffsetDate({ dateOffset: -1, endOfDay: true })
  },
  UPCOMING: {
    label: 'Upcoming',
    key: 'upcoming',
    start: getUnixTimestampForOffsetDate({ dateOffset: 1 })
  },
  PAST: {
    label: 'Past',
    key: 'past',
    end: getUnixTimestampForOffsetDate({ dateOffset: -1 })
  }
};

export const getDateRangeItemsForBidList = (selectedMenu) => {
  let dateRangeItems;
  switch (selectedMenu) {
    case TAB_CATEGORY_ENUM.ALL:
      dateRangeItems = Object.values(DATE_RANGE_ITEMS_FOR_EVENT_DATE);
      break;
    case TAB_CATEGORY_ENUM.NEW:
    case TAB_CATEGORY_ENUM.IN_PROGRESS:
    case TAB_CATEGORY_ENUM.SUBMITTED:
      dateRangeItems = [
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.ALL,
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.TODAY,
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.TOMORROW
      ];
      break;
    case TAB_CATEGORY_ENUM.EXPIRED:
      dateRangeItems = [
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.ALL,
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.YESTERDAY
      ];
      break;
    default:
      dateRangeItems = Object.values(DATE_RANGE_ITEMS_FOR_EVENT_DATE);
      break;
  }
  return dateRangeItems;
};

export const shouldShowEventDateRadio = ({ selectedTab, label }) => {
  switch (selectedTab) {
    case TAB_CATEGORY_ENUM.EXPIRED:
      return [
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.ALL.label,
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.YESTERDAY.label
      ].includes(label);
    case TAB_CATEGORY_ENUM.NEW:
    case TAB_CATEGORY_ENUM.IN_PROGRESS:
    case TAB_CATEGORY_ENUM.SUBMITTED:
      return [
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.ALL.label,
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.TODAY.label,
        DATE_RANGE_ITEMS_FOR_EVENT_DATE.TOMORROW.label
      ].includes(label);
    default:
      return true;
  }
};

export const getMenuFromCurrentURL = ({
  attribute,
  index,
  instantSearchRouter,
  setSelectedTab
}) => {
  const urlParams = new URLSearchParams(
    instantSearchRouter.getLocation().search
  );
  const menu = urlParams.get(`${index}[menu][${attribute}]`);
  setSelectedTab(menu);
};
